import React from "react";
import { Modal } from "semantic-ui-react";
import { modalTypes } from "./types";

const ModalShorthand = (props: modalTypes) => {
  const {
    dimmer = "blurring",
    isOpen,
    toggleModal,
    trigger,
    content,
    customStyles,
    className,
  } = props;
  const onClose = () => toggleModal(false);
  return (
    <Modal
      open={isOpen}
      dimmer={dimmer}
      trigger={trigger}
      className={className}
      style={customStyles}
      onClose={onClose}
      centered={true}
    >
      <Modal.Content>{content}</Modal.Content>
    </Modal>
  );
};

export default ModalShorthand;
