import React, { useEffect, useState } from "react";
import { useMutation } from '@apollo/client';
import { useHistory } from "react-router-dom";

import { LoginForm } from "../../../components/Forms/LoginForm/LoginForm";

import { DASHBOARD, MANAGER_DASHBOARD, SERVICE_PROVIDER_DASHBOARD } from "../../../constants/routes";
import { PURCHASER, MANAGER, PROVIDER } from "../../../constants";
import { LOGIN } from "../../../api/mutations/index";
import ForgotPasswordModal from "../../../components/ForgotPasswordModal";

const Login = (props:{mainTheme:any}) => {
  const history = useHistory();
  const [mutateLogin, { data, error }] = useMutation(LOGIN);
  const [forgotModal, toggleForgotModal] = useState(false)
  const [loading, toggleLoading] = useState(false)

  const [errorMessage, setError] = useState(null);

  const redirectUserSystem = (user: { role: number; }) => {
    let redirectRoute;
    switch (user?.role) {
      case PURCHASER:
        redirectRoute = DASHBOARD
        break
      case MANAGER:
        redirectRoute = MANAGER_DASHBOARD
        break
      case PROVIDER:
        redirectRoute = SERVICE_PROVIDER_DASHBOARD
        break
      default:
        redirectRoute = '/'
    }
    history.push(redirectRoute)
  }

  const checkLocalStorage = (token: any, user: { role: number; }) => {
    if (localStorage.getItem("token") === token) {
      redirectUserSystem(user)
    } else {
      checkLocalStorage(token, user)
    }
  }

  const onLoginMutation = () => {
    toggleLoading(false)
    if (data.login?.user?.id) {
      localStorage.setItem("token", data.login.token);
      checkLocalStorage(data.login.token, data.login.user)
    } else if (data.login?.message) {
      setError(data.login?.message)
      console.log(error)
    }
  }

  useEffect(() => {
    (data || error) && onLoginMutation()
  },[data, error])

  const handleLogin = async (values: { email: any; password: any; }) => {
    toggleLoading(true)
    try {
      mutateLogin({variables: {
        email: values.email,
        password: values.password,
      }})
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
     <LoginForm
        login={handleLogin}
        errorMessage={errorMessage}
        toggleForgotModal={toggleForgotModal}
        loading={loading}
        {...props}
      />
      <ForgotPasswordModal 
        forgotModal={forgotModal}
        toggleForgotModal={toggleForgotModal}
        user={{}}
        theme={props.mainTheme?.theme}
      />
    </>
  
  );
};

export default Login;
