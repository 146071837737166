export const updateTokenExpirationDate = (tokenExpirationDate: string) => {
    localStorage.removeItem('token-exp');
    localStorage.setItem('token-exp', tokenExpirationDate);
};

export const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
}

export const getTokenInternal = () => {
    const data = localStorage.getItem("token");
    const token = (data && JSON.parse(data).token) || '';

    return token;
};

export const saveTokenData = (token: any) => {
    const timestamp = +new Date();
    const data = JSON.stringify({ token, timestamp });

    localStorage.setItem("token", data);
};

export const saveToken = (token: string) => {
    localStorage.setItem("token", token);
}

export const isExpired = (exp: number) => {
    if (!exp) return false;

    return Date.now() > exp;
};

export const getExpirationDate = (token: string) => {
    if (!token) return null;

    const jwt = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());

    return (jwt && jwt.exp && jwt.exp * 1000) || null;
};
