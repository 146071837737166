import React, { useEffect, useState } from "react";
import { useMutation } from '@apollo/client';
import { useHistory } from "react-router-dom";

import VerificationForm from "../../../components/Forms/VerificationForm";

import { DASHBOARD, MANAGER_DASHBOARD, SERVICE_PROVIDER_DASHBOARD, SIGN_IN } from "../../../constants/routes";
import { PURCHASER, MANAGER, PROVIDER } from "../../../constants";
import { VERIFICATION, RESEND_VERIFICATION_CODE, VERIFY_FORGOT_PASSWORD } from "../../../api/mutations/index";

const Verification = (props:{ mainTheme: any }) => {
  const history = useHistory<any>();
  const [mutateVerifyCode, { data, error }] = useMutation(VERIFICATION);
  const [mutateResendEmail, { data: resendData, error: resendError }] = useMutation(RESEND_VERIFICATION_CODE);
  const [mutateVerifyPassword, { data: verifyData, error: verifyError }] = useMutation(VERIFY_FORGOT_PASSWORD);

  const [errorMessage, setError] = useState<null | string>(null);

  useEffect(() => {
    (data || error) && onLoginMutation()
  },[data, error])

  useEffect(() => {
    verifyData?.verifyForgotPassword?.token && onForgotPasswordSuccess(true)
    verifyData?.verifyForgotPassword?.__typename === "ErrorType" && onForgotPasswordSuccess(false)
  },[verifyData])

  useEffect(()=>{
    showResendEmailError()
  },[resendData])

  useEffect(() => {
    (history.location.state?.sendVerificationCode && history.location.state?.id) && handleResendEmail()
  }, [history.location.state?.id])

  const onForgotPasswordSuccess = (success: boolean) => {
    if (success) {
      setError("New password was sent to your Email")
      history.push(SIGN_IN)
    } else {
      setError(verifyData?.verifyForgotPassword?.message)
    }
  }

  const showResendEmailError = () => {
    if (resendData?.resendVerificationCode?.__typename === "ErrorType") {
      setError(resendData?.resendVerificationCode?.message)
    }
  }

  const redirectUserSystem = (user: { role: number; }) => {
    let redirectRoute;
    switch (user?.role) {
      case PURCHASER:
        redirectRoute = DASHBOARD
        break
      case MANAGER:
        redirectRoute = MANAGER_DASHBOARD
        break
      case PROVIDER:
        redirectRoute = SERVICE_PROVIDER_DASHBOARD
        break
      default:
        redirectRoute = '/'
    }
    history.push(redirectRoute)
  };

  const checkLocalStorage = (token: string | null, user: { role: number; }) => {
    if (localStorage.getItem("token") === token) {
      redirectUserSystem(user)
    } else {
      checkLocalStorage(token, user)
    }
  }

  const onLoginMutation = () => {
    if (data.verificationCode?.token) {
        localStorage.setItem("token", data.verificationCode?.token);
        checkLocalStorage(data.verificationCode?.token, history.location.state)
    } else if (data.verificationCode?.message) {
        setError(data.verificationCode?.message)
      console.log(error)
    }
  }


  const handleVerifyCode = async (values: { code: any; }) => {
    try {
      mutateVerifyCode({variables: {
        code: values.code,
        email: history.location.state?.email,
      }})
    } catch (error) {
      console.error(error);
    }
  };

  const handleVerifyPassword = async (values: { code: any; }) => {
    try {
      mutateVerifyPassword({variables: {
        code: values.code,
      }})
    } catch (error) {
      console.error(error);
    }
  };

  const handleResendEmail = async () => {
    console.log(history.location.state?.id)
    try {
      mutateResendEmail({variables: {
        email: history.location.state?.email ?? 'error',
      }})
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <VerificationForm
      verify={history.location.state?.isForgotPasswordForm ? handleVerifyPassword : handleVerifyCode}
      resendCode={handleResendEmail}
      isForgotPasswordForm={history.location.state?.isForgotPasswordForm}
      resetPassordSuccess={verifyData?.verifyForgotPassword?.token}
      errorMessage={errorMessage}
      mainTheme={props.mainTheme}
    />
  );
};

export default Verification;
