import * as Yup from "yup";
import { MIN_VERIFICATION_CODE_LENGTH } from "../../../constants/index";

export const VerificationSchema = Yup.object().shape({
  code: Yup.string()
    .min(
      MIN_VERIFICATION_CODE_LENGTH,
      `Code has to be longer than ${MIN_VERIFICATION_CODE_LENGTH} characters!`
    )
    .required("Password is required!")
});
