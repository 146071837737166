import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from '@apollo/client';

import { SignUpForm } from "../../../components/Forms/SignUpForm/signUpForm";
import { REGISTER } from "../../../api/mutations/index";
import { DASHBOARD, MANAGER_DASHBOARD, CONFIRM_EMAIL, SERVICE_PROVIDER_DASHBOARD } from "../../../constants/routes";
import { PURCHASER, MANAGER, PROVIDER } from "../../../constants";

const SignUp = (props:{mainTheme:any}) => {
    const history = useHistory();
    const [mutateRegister, { data, error }] = useMutation(REGISTER);
    const [loading, toggleLoading] = useState(false)

    const [errorMessage, setError] = useState<any>(null);

    const redirectUserSystem = (user: { role: number; }) => {
      let redirectRoute;
      switch (user?.role) {
        case PURCHASER:
          redirectRoute = DASHBOARD
          break
        case MANAGER:
          redirectRoute = MANAGER_DASHBOARD
          break
        case PROVIDER:
          redirectRoute = SERVICE_PROVIDER_DASHBOARD
          break
        default:
          redirectRoute = '/'
      }
      history.push(redirectRoute)
    };

    const checkLocalStorage = (token: any, user: { role: number; }) => {
      if (localStorage.getItem("token") === token) {
        redirectUserSystem(user)
      } else {
        checkLocalStorage(token, user)
      }
    }

    const onLoginMutation = () => {
      toggleLoading(false)
        if (data.register?.user?.id) {
            history.push(CONFIRM_EMAIL, {
              id: data.register?.user?.id,
              role: data.register?.user?.role,
              email: data.register?.user?.email
            })
        } else if (data.register?.message) {
            setError(data.register?.message)
          console.log(error)
        }
      }

    useEffect(() => {
        (data || error) && onLoginMutation()
      },[data, error])

    const handleSignUp = async (values: { 
      accept: any; 
      email: any; 
      password1: string; 
      password2: string; 
      manager: any; 
      phone: any;
      company: any
    }) => {
        if (!values.accept) return setError("Accept the agreement");
        toggleLoading(true)
        try {
            mutateRegister({variables: {
                email: values.email,
                password1: values.password1,
                password2: values.password2,
                role: values.manager ? MANAGER : PURCHASER,
                phone: values?.phone,
                company: values?.company
            }})
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <SignUpForm
            register={handleSignUp}
            errorMessage={errorMessage}
            loading={loading}
            {...props}
        />
    );
};

export default SignUp;
