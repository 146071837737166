import * as React from 'react';
import * as ReactDOM from "react-dom";

import { ApolloProvider} from '@apollo/client';
import client from "./api/apollo/index";

import { BrowserRouter } from "react-router-dom";

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import "font-awesome/css/font-awesome.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import 'semantic-ui-css/semantic.min.css';
import "./index.scss";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
        <App />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();

