import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Input, Form } from "semantic-ui-react";
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { useHistory } from "react-router-dom";

import { VerificationSchema } from "./validation";
import { SIGN_IN } from "../../../constants/routes";

import { verificationFormProps } from "./types";
import "./styles.scss";

const VerificationForm = (props: verificationFormProps) => {
  const history = useHistory();
  const { handleSubmit, control, formState: { errors, touchedFields } } = useForm({
    defaultValues: {
        code: ""
    },
    resolver: yupResolver(VerificationSchema)
  });

  return (
    <form
      onSubmit={handleSubmit(props.verify)}
      className="auth-form"
    >
        <Controller
            name="code"
            control={control}
            render={({ field }) => 
              <Form.Field className="auth-input mt-4">
                  <label>A confirmation code has been sent to your email address</label>
                  <Input 
                    className={`${(touchedFields.code && errors.code)?'error-input-border':''}`}
                    type='password' 
                    placeholder="Enter confirmation code"
                    {...field} 
                  />
              </Form.Field>
            }
        />
        <ErrorMessage
            errors={errors}
            name="code"
            render={({ message }) =>  
            <div style={{position: "relative"}}>
                <p
                    className="error-field hook-error"
                >
                {message}
                </p>
            </div>   
            }    
        />
        <div className="d-flex align-items-center auth-button-wrapp">
          {props.resetPassordSuccess ?
            <Button 
              type="button" 
              className="auth-button w-100 mx-0" 
              onClick={()=> history.push(SIGN_IN)}
              style={{
                background: props.mainTheme?.theme?.color ?? '#5CA76A',
              }}
            >
              Go to login
            </Button>
            :
            <Button 
              type="submit" 
              className="auth-button w-100 mx-0"
              style={{
                background: props.mainTheme?.theme?.color ?? '#5CA76A',
              }}
            >
              Send
            </Button>
          }
        </div>
        <p 
          style={{ position: "absolute", color: props.resetPassordSuccess ? 'green' : 'inherit'}} 
          className="error-field"
        >
          {props.errorMessage}
        </p>
        {!props.isForgotPasswordForm &&
          <div className='mt-3 resend-code'>
              <span style={{ color: '#BDBDBD'}}>
                  Did not receive the code?
              </span>
              <span 
                  style={{cursor:"pointer"}} 
                  className='link pl-1' 
                  onClick={() => props.resendCode()}
              >
                  Resend code
              </span>
          </div>
        }
    </form>
  )
}

export default VerificationForm;