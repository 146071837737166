import React from "react";
import Auth from "../AuthSystem";

const SignUpWrapper = () => {
    return (
        <Auth 
            signUp={true}
            signIn={false}
            confirmEmail={false}
        />
    )
}

export default SignUpWrapper;