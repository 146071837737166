// import gql from "graphql-tag";
import { gql } from '@apollo/client';

export const LOGIN = gql`
mutation login($email: String!, $password: String!){
    login(email: $email, password: $password)
    {
    ... on LoginSuccessType{
      user{
        id
        email
        role
      }
      token
    }
    ... on ErrorType{
      message
    }
  }
  }
`;
export const VERIFICATION = gql`
mutation verificationCode($email: String!, $code: String!){
  verificationCode(email: $email, code: $code){
    ... on CustomTokenType{
      token
    }
    ... on ErrorType{
      message
    }
  }
  }
`;

export const RESEND_VERIFICATION_CODE = gql`
mutation resendVerificationCode($email: String!){
  resendVerificationCode(email: $email){
    ... on SuccessType{
      message
    }
    ... on ErrorType{
      message
    }
  }
  }
`;

export const REGISTER = gql`
mutation register ($email: String!, $password1: String!,
$password2: String!, $phone: String, $company: String, $role: Int!){
  register(email: $email, password1: $password1,
    password2: $password2, phone: $phone, company: $company, role: $role)
  {
  ... on RegisterSuccessType{
    user{
      id
      email
      role
    }
  }
  ... on ErrorType{
    message
  }
}
}
`;

export const VERIFY_TOKEN = gql`
mutation verifyToken($token: String!){
  verifyToken(token: $token) {
    ...on VerifyTokenType{
      token {
        payload{
          exp
          origIat
          email
        }
      }
    }
  }
}
`;

export const CHANGE_PASSWORD = gql`
mutation changePassword($lastPassword:String!, $newPassword:String!){
  changePassword(lastPassword:$lastPassword, newPassword:$newPassword){
    ...on UserModelType{
      id
    }
    ...on ErrorType{
      message
    }
  }
}
`;

export const UPDATE_USER = gql`
mutation updateUser($data: UserInputs!){
  updateUser(data: $data){
    ... on UserModelType{
      id
      name
      lastName
      avatar{url}
      company
      language
    }
    ... on ErrorType{
      message
    }
  }}
`;

export const FORGOT_PASSWORD = gql`
mutation sendForgotPassword($email: String!){
  sendForgotPassword(email: $email){
    ... on SuccessType{
      message
    }
    ... on ErrorType{
      message
    }
  }
  }
`;

export const VERIFY_FORGOT_PASSWORD = gql`
mutation verifyForgotPassword($code: String!){
  verifyForgotPassword(code: $code){
    ... on CustomTokenType{
      token
    }
    ... on ErrorType{
      message
    }
  }
  }
`;

export const SET_NEW_BOOKING_STATUS = gql`
mutation bookStatus($bookId: Int!, $status: Boolean!) {
  bookStatus(bookId: $bookId, status: $status) {
    ... on SuccessType {
      __typename
      message
    }
    ... on ErrorType {
      __typename
      message
    }
  }
}
`;


