import React from "react";
import { useHistory } from "react-router-dom";
import * as path from "../../constants/routes";

const AuthToggle = (props: { authForm: string; setAuthForm: any, mainTheme: any }) => {
    const history = useHistory()

    const handleToggleAuth = (type: string) => {
        if (type === 'signIn') {
            history.push(path.SIGN_IN)
        } else if (type === 'signUp') {
            history.push(path.SIGN_UP)
        }
    }

  return (
    <div className="auth-toggle">
        <div
            className="d-flex justify-content-center align-items-center w-50 pointer"
            onClick={() => handleToggleAuth('signIn')}
        >
            <div className={`toggle-item`}>
                <p>Sign in</p>
            </div>
        </div>
        <div
            className="d-flex justify-content-center align-items-center w-50 pointer"
            onClick={() => handleToggleAuth('signUp')}
        >
            <div className={`toggle-item`}>
                <p>Sign up</p>
            </div>
        </div>
        <div className={`active-item ${props.authForm === 'signIn' ? 'signIn-toggle' : 'signUp-toggle'}`}></div>
    </div>
  )
};

export default AuthToggle;
