import gql from "graphql-tag";

export const ME = gql`
query me{
  me{
    ... on UserModelType {
      id
      email
      avatar {
        url
      }
      company
      dateJoined
      isVerified
      lastAvailableDate
      lastName
      name
      language
      phoneNumber
      role
      website
    }
    ...on ErrorType{
      message
    }
  }
}
`;

export const RANKING_REGIONS = gql`
query rankingHarvestRegions($year: String!, $filters: String!, $month: Int!, $week: Int!) {
  rankingHarvestRegions(year: $year, filters: $filters, month: $month, week: $week) {
    ...on RegionsPlantedType{
      regions{
        id
        name
        plantedArea
        plantedTotal
        availableAgricultureLand
      }
    }
        ...on RegionsHarvestType{
      regions{
        id
        name
        plantedArea
        harvestForecast
      }
    }
      ...on RegionsCropRiskType{
      regions{
        id
        name
        plantedArea
        harvestForecast
        cropRisk
      }
  }
    ...on ErrorType{
      message
    }
  }
}
`;

export const SERIALIZE_REGIONS = gql`
query serializeRegions {
  serializeRegions{
    ...on JSONValue{
      value
    }
    ...on ErrorType{
      message
    }
  }
}
`;

export const SERIALIZE_COMMUNES = gql`
  query serializeHarvestCommunes(
    $year: String!
    $month: Int!
    $filters: String!
    $communePk: Int
    $week: Int!
    $farm: Int
  ) {
    serializeHarvestCommunes(
      year: $year
      month: $month
      filters: $filters
      communePk: $communePk
      week: $week
      farm: $farm
    ) {
      ... on JSONValue {
        value
      }
      ... on ErrorType {
        message
      }
    }
  }
`;

export const RANKING_COMMUNES = gql`
query rankingHarvestCommunes($year: String!, $filters: String!, $month: Int!, $cp: Boolean!, $load: Boolean,$pk: Int, $communePk: Int, $week: Int!, $farm: Int) {
  rankingHarvestCommunes(year: $year, filters: $filters, month: $month, cp: $cp, load: $load, pk: $pk, communePk: $communePk, week: $week, farm: $farm)
  {
    ... on CommunesHarvestType{
      communes{
        id
        name
        region {
          id
        }
        subRegion {
          id
        }
        cp
        plantedArea
        harvestForecast
      }
    }
      ...on CommunesPlantedType{
        communes{
          id
          name
          region {
            id
          }
          subRegion {
            id
          }
          cp
          availableAgricultureLand
          plantedArea
          plantedTotal
        }
      }
       ...on CommunesCropRiskType{
        communes{
          id
          name
          region {
            id
          }
          subRegion {
            id
          }
          cp
          plantedArea
          harvestForecast
          cropRisk
        }
    }
    ...on FieldsType{
      fields{
        name
        plantedArea
        availableAgricultureLand
        plantedTotal
        harvestForecast
        cropRisk
        farm {
          id
          name
          farmLeader {
            id
            name
          }
        }
        cp
        id
      }
    }
    ...on FarmsType{
      farms {
        id
        name
        plantedArea
        harvestForecast
        plantedTotal
        availableAgricultureLand
        cropRisk
        cp
        averageNdvi
        averageNdmi
        ndviColor
        ndmiColor
        averageYield
        farmLeader {
          id
          name
          plantedArea
          harvestForecast
          plantedTotal
          availableAgricultureLand
          cropRisk
          cp
          averageNdvi
          averageNdmi
          ndviColor
          ndmiColor
          averageYield
          mobile
        }
      }
    }
    ...on ErrorType{
      message
    }
  }
}
`;

export const RANKING_COMMUNE = gql`
query rankingCommune($pk: Int, $year: Int!, $month: Int!) {
  rankingCommune(pk:$pk, year: $year, month: $month) {
        ...on CommunesAttributesType{
      communesAttr{
        communeName
        harvestForecast
        plantedArea
      }
    }
  }
}
`;

export const COLLECTION_POINTS = gql`
query collectionsPoints{
  collectionsPoints{
    ...on JSONValue{
      value
    }
  }
}
`;

export const COLLECTION_POINT = gql`
query collectionPoint($cpId: Int) {
  collectionPoint(cpId: $cpId) {
    ... on JSONValue{
      value
    }
  }
}
`;

export const RANKING_CP_COMMUNES = gql`
query rankingCpCommunes($year: String!, $filters: String!, $month: Int!, $cpId: Int) {
  rankingCpCommunes(year: $year, filters: $filters, month: $month, cpId: $cpId) {
    ...on CommunesHarvestType{
      communes{
				id
        name
      }
    }
  }
}
`;

export const TORAL_HARVEST_VALUES = gql`
query totalHarvestValues($year: String!, $filters: String!, $month: Int!, $pk: Int, $communePk: Int, $week: Int!, $farm: Int){
  totalHarvestValues(year: $year, filters: $filters, month: $month, pk: $pk, communePk: $communePk, week: $week, farm: $farm)
}
`;

//planter development

export const SERIALIZE_PLANT_COMMUNES = gql`
  query serializePlantCommunes($year: String!, $month: Int!, $week:Int!, $plant: String!, $communePk: Int, $farm: Int){
    serializePlantCommunes(year:$year,month:$month,week:$week,plant: $plant, communePk: $communePk, farm: $farm){
      ... on JSONValue{
        value
      }
    }
  }
`;

export const RANKING_PLANT_REGIONS = gql`
  query rankingPlantRegions($year: String!, $month: Int!, $week:Int!, $plant: String!, $grownStageColor: [String!]){
    rankingPlantRegions(year:$year,month:$month,week:$week,plant: $plant, grownStageColor: $grownStageColor){
      ...on RegionsFarmerPlantType{
        regions{
          id
          name
          size
          gdd
          grownStage
        }
      }
    }
  }
`;

export const RANKING_PLANT_COMMUNES = gql`
  query rankingPlantCommunes($year: String!, $month: Int!, $week:Int!, $plant: String!, $pk: Int, $load: Boolean, $communePk: Int, $farm: Int){
    rankingPlantCommunes(year:$year,month:$month,week:$week,plant: $plant,pk:$pk, load: $load, communePk: $communePk, farm: $farm){
          ... on CommunesFarmerPlantType{
        communes{
          id
          name
          size
          gdd
          grownStage
          color
          region {
            id
          }
          subRegion {
            id
          }
          cp
        }
      }
      ...on FieldsFarmerPlantType{
        fields{
          id
          name
          size
          gdd
          grownStage
          cp
        }
      }
      ...on FarmsTypePlantType{
        farms{
          id
          name
          size
          gdd
          grownStage
          cp
        }
      }
    }
  }
`;

export const TOTAL_PLANT_VALUES = gql`
  query totalPlantValues($year: String!, $month: Int!, $week:Int!, $pk: Int, $plant: String!, $grownStageColor: [String!], $communePk: Int){
    totalPlantValues(year:$year,month:$month,week:$week, pk:$pk, plant: $plant, grownStageColor: $grownStageColor, communePk: $communePk)
  }
`;

export const GET_PLANTS = gql`
  query getPlants {
    getPlants
  }
`;

//theme

export const GET_THEME = gql`
  query theme {
    theme {
     color
     farmItems {
      id
      name
     } 
     logoUrl
     authLogoUrl
     name
     favicon
     partnerLogo {
        isActive
        name
        logo
      }
    }
  }
`;

//service-provider-page

export const ICARDA_BOOKING = gql`
  query icardaBooking($filters: String) {
    icardaBooking(filters: $filters) {
      id
      date
      status
      orderNumber
      plantedArea
      farmer {
        id
        name
        mobile
      }
      provider {
        id
        name
        mobile
      }
      mechanism{
        id
        mechanismType
        provider {
          id
          name
          mobile
        }
      }
    }
  }
`;

export const NEW_ICARDA_BOOKING_REQUESTS = gql`
query icardaNewBooking {
  icardaNewBooking {
    id
    date
    status
    orderNumber
    farmer {
      id
      name
      mobile
    }
    provider {
      id
      name
      mobile
    }
    mechanism{
      id
      mechanismType
      provider {
        id
        name
        mobile
      }
    }
  }
}
`;

export const SEEDERS_REQUEST = gql`
query icardaSeeders($date: String) {
  icardaSeeders(date: $date) {
    id
    location
    mechanismType
    model
    imageUrl
    status
    provider {
      id
      mobile
      name
    }
    availableDates {
      isAvailable
      availableDate
    }
  }
}
`;

//servie-provider-map

export const SERVICE_PROVIDERS_LIST = gql`
query icardaServiceProviders($providerId: Int) {
  icardaServiceProviders(providerId: $providerId) {
    id
    name
  }
}
`;

export const SERVICE_PROVIDERS_POLYGONS = gql`
query icardaSerializeServiceProviders($providerId: Int) {
  icardaSerializeServiceProviders(providerId: $providerId) {
    ... on ErrorType {
      __typename
      message
    }
    ... on JSONValue {
      __typename
      value
    }
  }
}
`;

//dam-water-levels

export const DAMS_SELECTOR_OPTIONS = gql`
query allDamsNames {
  allDamsNames
}
`;

export const DAMS_MONTHS_SELECTOR_OPTIONS = gql`
query allDamsDates {
  allDamsDates
}
`;

export const DAM_INSIGHTS = gql`
query rankingDam($month: String!, $damId: Int, $year: String!) {
  rankingDam(month: $month, damId: $damId, year: $year)
}
`;

export const DAMS_RANKING = gql`
query rankingDams($month: String!, $load: Boolean, $year: String!) {
  rankingDams(month: $month, load: $load, year: $year) {
    reserves {
      id
      dam {
        id
        name
        totalCapacity
      }
      date
      reserveCurrent
      reservePrevious
      name
      normalCapacity
      totalCapacity
    }
    currentYear
    previousYear
    currentTotalReserve
    previousTotalReserve
    totalNormalReserve
  }
}
`;

export const DAM_POLYGONS = gql`
query serializeDamp($month: String!, $damId: Int, $year: String!) {
  serializeDamp(month: $month, damId: $damId, year: $year) {
    ... on ErrorType {
      __typename
      message
    }
    ... on JSONValue {
      __typename
      value
    }
  }
}
`;

export const DAMS_DIAGRAM = gql`
query damsDiagram($damId: Int!) {
  damsDiagram(damId: $damId) {
    totalCapacity
    year
    date
    month
    reserveCurrent
    reservePrevious
  }
}
`;








