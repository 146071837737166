export const HOME = "/";
export const SIGN_IN = "/login";
export const SIGN_UP = "/signup";
export const DASHBOARD = "/dashboard";
export const MANAGER_DASHBOARD = "/dashboard/manager";
export const SERVICE_PROVIDER_DASHBOARD = "/dashboard/service-provider";
export const MAP = "/map";
export const TASKS = "/dashboard/tasks";
export const PROFILE = "/dashboard/profile";
export const MANAGER_PROFILE = "/dashboard/manager/profile";
export const SERVICE_PROVIDER_PROFILE = "/dashboard/service-provider/profile";
export const CONFIRM_EMAIL = "/confirm-email";
export const RESET_PASSWORD = "/reset-password/:uid/:confirmToken";
