import gql from "graphql-tag";

//weather-compaign

export const SERIALIZE_WEATHER_COMMUNES = gql`
query serializeWeatherCommunes($month: Int!, $filters: Int!, $regionPk: Int, $year: Int!){
  serializeWeatherCommunes(month: $month, filters: $filters, regionPk: $regionPk, year: $year) {
    ... on ErrorType {
      __typename
      message
    }
    ... on JSONValue {
      __typename
      value
    }
  }
}
`;

export const WEATHER_INSIGHTS = gql`
query totalWeatherValues($month: Int!, $filters: Int!, $regionPk: Int, $year: Int!) {
  totalWeatherValues(month: $month, filters: $filters, regionPk: $regionPk, year: $year)
}
`;

export const WEATHER_REGIONS_RANKING = gql`
query regionsWeatherRanking($month: Int!, $filters: Int!, $regionPk: Int, $year: Int!) {
  regionsWeatherRanking(month: $month, filters: $filters, regionPk: $regionPk, year: $year) {
    regions {
      ... on SubRegionWeatherType {
        id
        name
        precipAnomalyCurrent
        precipCurrent
        tempAvgCurrent
        tempAnomalyCurrent
      }
      ... on RegionWeatherType {
        id
        name
        precipAnomalyCurrent
        precipCurrent
        tempAnomalyCurrent
        tempAvgCurrent
      }
    }
  }
}
`;

export const WEATHER_COMMUNES_RANKING = gql`
query communesWeatherRanking($month: Int!, $filters: Int!, $regionPk: Int, $load: Boolean, $year: Int!) {
  communesWeatherRanking(month: $month, filters: $filters, regionPk: $regionPk, load: $load, year: $year) {
    communes {
      id
      name
      region {
        id
      }
      subRegion {
        id
      }
      tempAvgCurrent
      tempAnomalyCurrent
      precipAnomalyCurrent
      precipCurrent
    }
  }
}
`;

export const WEATHER_YEARS = gql`
query availableWeatherYears {
  availableWeatherYears
}
`;

export const WEATHER_SEASONS = gql`
query availableWeatherSeasons {
  availableWeatherSeasons
}
`;


export const WEATHER_MONTHS = gql`
query availableWeatherMonths($year: Int!) {
  availableWeatherMonths(year: $year)
}
`;

export const WEATHER_GRAPH = gql`
query weatherAverageDiagram($regionPk: Int!, $filters: Int!, $year: Int!, $communeId: Int) {
  weatherAverageDiagram(regionPk: $regionPk, filters: $filters, year: $year, communeId: $communeId)
}
`;