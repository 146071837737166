export const saveData = (key: string, value: any) => {
  const timestamp = +new Date();
  const data = JSON.stringify({ token: value, timestamp });
  window.localStorage.setItem(key, data);
};

export const getBase64 = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const separateThousends = (cellValue: any) => {
  if (Number.isFinite(cellValue)) {
      const specifyDecimalHundredths = cellValue.toLocaleString('en',{
          minimumFractionDigits: 2,
        })
        .replaceAll(',',' ');
      return specifyDecimalHundredths;
  } else {
      return cellValue;
  }
}

export const separateThousendsWithMeasure = (cellValue: any) => {
  if (Number.isFinite(cellValue)) {
      const specifyDecimalHundredths = cellValue.toLocaleString('en',{
          minimumFractionDigits: 2,
        })
        .replaceAll(',',' ');
      let result = specifyDecimalHundredths ?? 'N/A';
      return result;
  } else if (typeof cellValue === "string"){
    const getStringParts = cellValue.split(' ');
    const getValue = Number(getStringParts[0]).toLocaleString('en',{
      minimumFractionDigits: 2,
    })
    .replaceAll(',',' ');
    const getMeasure = getStringParts[1]
    let result = (getMeasure && getValue) ? ((getValue+' ')+ getMeasure) : 'N/A';
    return result;
  } else {
    return 'N/A'
  }
};

export const getCropRiskLevel = (cropRisk: number) => {

  if (cropRisk > 0.79 ){
      return 'Very High';
  } else if (cropRisk > 0.59 ) {
      return 'High';
  } else if (cropRisk > 0.49 ) {
      return 'Moderate';
  }else if(cropRisk > 0.19 ){
      return 'Low';
  } else if (cropRisk < 0.20 ) {
      return 'Very Low'
  }

};

export const setMetadata = (favicon: string, title: string) => {
  if (title) {
    document.title = title;
  }
  if (!favicon) return null;

  let oldFavicon = document.getElementById('favicon')
  if (oldFavicon instanceof HTMLLinkElement) {
    oldFavicon.href = favicon
  }
};

export const isIcarda = () => {
  return window.location.origin.includes('icarda');
}

// export const getLogisticsTitle = () => {
//   if(window.location.origin.includes('icarda')) {
//     return {
//       title: 'All service providers',
//       abbr: 'SP',
//       default: 'No chosen SP'
//     }
//   } else {
//     return {
//       title: 'All collection points',
//       abbr: 'CP',
//       default: 'No chosen CP'
//     }
//   }
// }

export const getLogisticsTitle = () => {
    return {
      title: 'All collection points',
      abbr: 'CP',
      default: 'No chosen CP'
    }
};

export const getRankingFeaturesType = (zoomType: string, requestFeatures: { fields: any; farms: any; communes: any; farmers:any}, ...values: (boolean | undefined)[]) => {
  if (values?.[0]) return requestFeatures?.farmers
  switch (zoomType) {
    case 'farm':
      return requestFeatures?.fields
    case 'farms':
    case 'farmLeader':
      return requestFeatures?.farms
    default:
      return requestFeatures?.communes
  }
};

export const getBottomTableFeatures = (zoomType: string, communsFeatures: any, farmsFeatures: any) => {
  switch (zoomType) {
    case 'farm':
    case 'farms':
    case 'farmLeader':
      return farmsFeatures
    default:
      return communsFeatures
  }
} 

export const getFilteredListData = (arr: any[], searchValue: string) => {
  if (searchValue?.length < 1 || !arr) return arr;
  searchValue = String(searchValue).toLowerCase();

  const searchNested = (obj: any): boolean => {
    if (typeof obj === 'object' && obj !== null) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (typeof value === 'object' && value !== null) {
            if (searchNested(value)) {
              return true;
            }
          } else if (
            String(value)
              .toLowerCase()
              .includes(searchValue)
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  return arr.filter(o => searchNested(o));
};