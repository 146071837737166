import gql from "graphql-tag";

//quality_of_campaign

export const SERIALIZE_MANAGER_COMPAIGN_COMMUNES = gql`
query serializeManagerCommunes(
    $year: String!
    $month: Int!
    $filters: String!
    $communePk: Int
    $week: Int!
    $farm: Int
  ) {
    serializeManagerCommunes(
      year: $year
      month: $month
      filters: $filters
      communePk: $communePk
      week: $week
      farm: $farm
    ) {
      ... on JSONValue {
        value
        __typename
      }
      ...on ErrorType{
        message
      }
      __typename
    }
  }
`;

export const TOTAL_MANAGER_VALUES = gql`
query totalManagerValues($year: String!, $month: Int!, $filters: String!, $pk: Int, $communePk: Int,$week:Int!, $farm: Int){
  totalManagerValues(year:$year,month: $month, filters:$filters, pk: $pk, communePk: $communePk, week: $week, farm: $farm)
}
`;

export const RANKING_MANAGER_REGIONS = gql`
query rankingManagerRegions($year: String!, $filters: String!, $month: Int!,$week:Int!) {
  rankingManagerRegions(year: $year, filters: $filters, month: $month, week: $week) {
    ...on RegionsPlantedType{
      regions{
        id
        name
        plantedArea
        plantedTotal
        availableAgricultureLand
      }
    }
        ...on RegionsHarvestType{
      regions{
        id
        name
        plantedArea
        harvestForecast
      }
    }
   ...on RegionsNdviNdmiType{
      regions{
        id
        name
        size
        averageNdvi
        averageNdmi
        standardDeviation
      }
    }
    ...on RegionsCropRiskType{
      regions{
        id
        name
        plantedArea
        harvestForecast
        cropRisk
      }
    }
    ...on ErrorType{
      message
    }
  }
}
`;

export const RANKING_MANAGER_COMMUNES = gql`
query rankingManagerCommunes($year: String!, $filters: String!, $month: Int!, $pk: Int, $cp: Boolean!, $load: Boolean, $communePk: Int,$week:Int!, $farm: Int) {
  rankingManagerCommunes(year: $year, filters: $filters, month: $month, pk: $pk, cp: $cp, load: $load, communePk: $communePk, week: $week, farm: $farm){
    ...on CommunesHarvestType{
        communes{
            id
            name
            region {
              id
            }
            subRegion {
              id
            }
            cp
            plantedArea
            harvestForecast
        }
    }
    ...on CommunesPlantedType{
        communes{
            id
            name
            region {
              id
            }
            subRegion {
              id
            }
            cp
            availableAgricultureLand
            plantedArea
            plantedTotal
        }
    }
    ...on CommunesNdviNdmiType{
      communes{
        id
        name
        size
        averageNdvi
        averageNdmi
        cp
        region {
          id
        }
        subRegion {
          id
        }
      }
    }
    ...on CommunesCropRiskType{
      communes{
        id
        name
        region {
          id
        }
        subRegion {
          id
        }
        cp
        plantedArea
        harvestForecast
        cropRisk
      }
    }
    ...on FieldsType{
      fields{
        name
        plantedArea
        availableAgricultureLand
        plantedTotal
        harvestForecast
        cropRisk
        size
        averageNdvi
        averageNdmi
        cp
        id
      }
    }
    ...on FarmsType{
      farms {
        id
        name
        plantedArea
        harvestForecast
        plantedTotal
        availableAgricultureLand
        cropRisk
        cp
        averageNdvi
        averageNdmi
        ndviColor
        ndmiColor
        averageYield
        farmLeader {
          id
          name
          plantedArea
          harvestForecast
          plantedTotal
          availableAgricultureLand
          cropRisk
          cp
          averageNdvi
          averageNdmi
          ndviColor
          ndmiColor
          averageYield
          mobile
        }
      }
    }
    ...on ErrorType{
      message
    }
  }
}
`;

export const CROP_RISK_MANAGER_URL = gql`
  query cropRiskManagerApiUrl($data:CropApiInputs!){
    cropRiskManagerApiUrl(data:$data)
  }
`;

export const CROP_RISK_MANAGER_DATA = gql`
  query cropRiskManagerApiData($communeId: Int!){
    cropRiskManagerApiData(communeId:$communeId)
  }
`;