import React, { lazy, Suspense } from "react";
import { withRouter, Switch, Route } from "react-router";

import { Loader } from "semantic-ui-react";

import * as path from "../src/constants/routes";

import PageNotFound from "./components/PageNotFound";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Verification from "./pages/Verification";

import "./App.scss";

const ManagerDashboard = lazy(() => import("./pages/ManagerDashboard"));
const PurchaserDashboard = lazy(() => import("./pages/PurchaserDashboard"));
const ManagerProfile = lazy(() => import("./pages/ManagerProfile"));
const PurchaserProfile = lazy(() => import("./pages/PurchaserProfile"));
const ServiceProviderDashboard = lazy(() => import("./pages/ServiceProviderDashboard"));
const ServiceProviderProfile = lazy(() => import("./pages/PurchaserProfile"));

const App = () => {
    return (
      <Switch>
        <Route exact path={path.HOME} render={() => <SignIn />} />
        <Route exact path={path.SIGN_IN} render={() => <SignIn />} />
        <Route exact path={path.SIGN_UP} render={() => <SignUp />} />
        <Route exact path={path.CONFIRM_EMAIL} render={() => <Verification/>} />
        <Suspense fallback={<Loader active/>}>
          <Switch>
            <Route exact path={path.DASHBOARD} render={() => <PurchaserDashboard />} />
            <Route exact path={path.MANAGER_DASHBOARD} render={() => <ManagerDashboard/>} />
            <Route exact path={path.PROFILE} render={() => <PurchaserProfile />} />
            <Route exact path={path.MANAGER_PROFILE} render={() => <ManagerProfile />} />
            <Route exact path={path.SERVICE_PROVIDER_DASHBOARD} render={() => <ServiceProviderDashboard profile={false}/>} />
            <Route exact path={path.SERVICE_PROVIDER_PROFILE} render={() => <ServiceProviderDashboard profile={true}/>} />
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </Suspense>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    );
}

export default withRouter(App);
