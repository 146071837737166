import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Input, Form } from "semantic-ui-react";
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';

import { VerificationSchema } from "./validation";
import { forgotPasswordProps } from "./types";
import "./styles.scss";

const ForgotPasswordForm = (props: forgotPasswordProps) => {
  const { handleSubmit, control, formState: { errors, touchedFields } } = useForm({
    defaultValues: {
        email: ""
    },
    resolver: yupResolver(VerificationSchema)
  });

  return (
    <form
      onSubmit={handleSubmit(props.handleForgotPassword)}
      className="auth-form"
    >
        <Controller
            name="email"
            control={control}
            render={({ field }) => 
              <Form.Field className="auth-input mt-4">
                  <label>Enter your email to reset password</label>
                  <Input 
                    className={`${(touchedFields.email && errors.email)?'error-input-border':''}`}
                    type='email' 
                    placeholder="Enter your email"
                    {...field} 
                  />
              </Form.Field>
            }
        />
        <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) =>  
            <div style={{position: "relative"}}>
                <p
                    className="error-field hook-error"
                >
                {message}
                </p>
            </div>   
            }    
        />
        <div className="d-flex align-items-center auth-button-wrapp">
            <Button 
              type="submit" 
              className="auth-button w-100 mx-0"
              style={{background: props.theme?.color ?? '#5CA76A' }}
            >
              Send
            </Button>
        </div>
        <p style={{ position: "absolute"}} className="error-field">{props.errorMessage}</p>
        {/* <div className='mt-3 resend-code'>
            <span style={{ color: '#BDBDBD'}}>
                Did not receive the code?
            </span>
            <span 
                style={{cursor:"pointer"}} 
                className='link pl-1' 
                onClick={() => props.resendCode()}
            >
                Resend code
            </span>
        </div> */}
    </form>
  )
}

export default ForgotPasswordForm;