import gql from "graphql-tag";

export const ANALYTICS_YIELD_HARVEST_FORECAST = gql`
query analyticsYieldHarvestForecast($month: Int!, $regionId: Int, $communeId: Int, $year: String!){
    analyticsYieldHarvestForecast(month: $month, regionId: $regionId, communeId: $communeId, year: $year)
}
`;

// export const ANALYTICS_NDVI_NDMI_GRAPH = gql`
// query analyticsNdviNdmiGraph($regionId: Int, $communeId: Int, $year: String, $month: Int){
//     analyticsNdviNdmiGraph(regionId: $regionId, communeId: $communeId, year: $year, month: $month)
// }
// `;

export const ANALYTICS_NDVI_NDMI_GRAPH = gql`
query analyticsNdviNdmiGraph($regionId: Int, $communeId: Int, $year: String){
    analyticsNdviNdmiGraph(regionId: $regionId, communeId: $communeId, year: $year)
}
`;

export const ANALYTICS_AVERAGE_TEMPERATURE = gql`
query analyticsAverageTemperature($month: Int!, $regionId: Int, $communeId: Int, $year: String!){
    analyticsAverageTemperature(month: $month, regionId: $regionId, communeId: $communeId, year: $year)
}
`;

export const ANALYTICS_PRECIPITATION = gql`
query analyticsPrecipitation($month: Int!, $regionId: Int, $communeId: Int, $year: String!){
    analyticsPrecipitation(month: $month, regionId: $regionId, communeId: $communeId, year: $year)
}
`;

export const ANALYTICS_CROP_RISK_GRAPH = gql`
  query analyticsCropRiscGraph($year: String!, $regionId: Int!) {
    analyticsCropRiscGraph(year: $year, regionId: $regionId)
  }
`;
