import React from "react";
import Auth from "../AuthSystem";

const SignInWrapper = () => {
    return (
        <Auth 
            signUp={false}
            signIn={true} 
            confirmEmail={false}
        />
    )
}

export default SignInWrapper;