import gql from "graphql-tag";

export const SOIL_FIELD_GRAPH = gql`
query soilFieldGraph($fieldPk: Int!, $year: String!){
    soilFieldGraph(fieldPk: $fieldPk, year: $year)
  }
`;

export const VEGETATION_FIELD_GRAPH = gql`
query vegetationFieldGraph($fieldPk: Int!, $year: String!){
    vegetationFieldGraph(fieldPk: $fieldPk, year: $year)
  }
`;

export const TEMPERATURE_FIELD_GRAPH = gql`
query temperatureFieldGraph($fieldPk: Int!, $year: String!){
  temperatureFieldGraph(fieldPk: $fieldPk, year: $year)
  }
`;

