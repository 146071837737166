import React, { useState, useEffect } from "react";
import { useMutation } from '@apollo/client';
import { useHistory } from "react-router-dom";

import * as path from "../../constants/routes";
import Modal from "../Modal/Modal";
import ForgotPasswordForm from "../Forms/ForgotPasswordForm";

import { FORGOT_PASSWORD } from "../../api/mutations";

const ForgotPasswordModal = (props: {forgotModal: any; toggleForgotModal: any; user: any; theme: any}) => {
    const history = useHistory()
    const [mutateForgotPassword, { data: forgotData, error: forgotError }] = useMutation(FORGOT_PASSWORD);
    const [forgotPasswordMessage, setForgotPasswordMessage] = useState(null);
    const [email, setEmail] = useState(null)

    useEffect(()=>{
        forgotData?.sendForgotPassword?.__typename === "SuccessType" && redirectToForgotPasword()
        forgotData?.sendForgotPassword?.__typename === "ErrorType" && setForgotPasswordMessage(forgotData?.sendForgotPassword?.message)
    },[forgotData])

    const redirectToForgotPasword = () => {
        localStorage.removeItem("token");
        history.push(path.CONFIRM_EMAIL, {
            id: props.user?.id ?? null,
            role: props.user?.role ?? null,
            sendVerificationCode: false,
            isForgotPasswordForm: true,
            email: email ?? null,
          })
    }

    const handleForgotPassword = async (values: { email: any }) => {
        values?.email && setEmail(values?.email)
        try {
            mutateForgotPassword({variables: {
                ...values
            }})
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal
            trigger={undefined}
            className={undefined}
            withCloseIcon={undefined}
            isOpen={props.forgotModal}
            toggleModal={() => props.toggleForgotModal(false)}
            customStyles={{ width: "400px", height: '300px' }}
            content={
            <div className="map_modal" style={{
                    height:"100%", 
                    display:"flex", 
                    alignItems: "center",
                    padding: '0 30px'
                }}>
                <ForgotPasswordForm 
                    handleForgotPassword={handleForgotPassword}
                    errorMessage={forgotPasswordMessage}
                    theme={props.theme}
                />
            </div>
            }
        />
    )
}

export default ForgotPasswordModal;