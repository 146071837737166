import React, { useState, useEffect, Fragment } from "react";
import { useQuery } from '@apollo/client';
import { useHistory } from "react-router-dom";

import AuthToggle from "../../components/AuthSystem/AuthToggle";
import Login from "./Login";
import SignUp from "./SignUp";
import Verification from "./Verification"
import AuthFooter from "../../components/Footer/AuthFooter";

import logo from "../../assets/zar3-logo.png";

import * as path from "../../constants/routes";
import { ME } from "../../api/queries/common";
import { GET_THEME } from "../../api/queries/common";
import { PURCHASER, MANAGER } from "../../constants";
import { setMetadata } from "../../utils";

import "./scss/index.scss";
import "./scss/App.scss";

const Auth = (props: { signIn: boolean; signUp: boolean; confirmEmail: boolean}) => {
  const [authForm, setAuthForm] = useState('signIn');
  const [preloadFonts, setFontsReady] = useState(false);
  const { data: userData } = useQuery(ME, {
    fetchPolicy: 'network-only',
  });
  const { data: mainTheme } = useQuery(GET_THEME);

  const history = useHistory();

  useEffect(() => {
    document.fonts.ready.then(() => setFontsReady(true));
    userData?.me?.isVerified && isAuthorised()
  }, [userData])

  useEffect(()=> {
    toogleAuthForm()
  },[props.signIn, props.signUp])

  useEffect(()=>{
    mainTheme && setMetadata(mainTheme?.theme?.favicon, mainTheme?.theme?.name)
  },[mainTheme])

  const isAuthorised = () => {
    if (!localStorage.getItem("token")) return; 
    if (userData?.me?.role === PURCHASER) {
      history.push(path.DASHBOARD)
    } else if (userData?.me?.role === MANAGER) {
      history.push(path.MANAGER_DASHBOARD)
    }
  }

  const toogleAuthForm = () => {
    props.signIn
      ? setAuthForm('signIn')
      : setAuthForm('signUp')
  }

  return (
    <div className="auth-wrapp">
      {preloadFonts && mainTheme &&
        <Fragment>
          <div className="auth-container">
            <div 
              className="auth-logo"
              onClick={() => history.push(path.HOME)}
            >
              <img src={mainTheme?.theme?.authLogoUrl} alt="logo" />
            </div>
              {props.confirmEmail ? 
                  <div className="w-100">
                    <Verification mainTheme={mainTheme}/>
                  </div>
                :
                <>
                  <AuthToggle 
                    authForm={authForm}
                    setAuthForm={setAuthForm}
                    mainTheme={mainTheme}
                  />
                  <div className="w-100">
                    {authForm === 'signIn'
                      ? <Login mainTheme={mainTheme}/>
                      : <SignUp mainTheme={mainTheme}/>
                    }
                  </div>
                </>
              }
          </div>
          <AuthFooter />
        </Fragment>
      }
    </div>
  )
};

export default Auth;
