import React,{ useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Input, Form, Icon } from "semantic-ui-react";
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { Loader } from 'semantic-ui-react';

import { LoginSchema } from "./validation";
import { loginProps } from "./types"; 

import "./styles.scss";

export const LoginForm = (props: loginProps) => {
  const { handleSubmit, control, formState: { errors, touchedFields } } = useForm({
    defaultValues: {
        email: "",
        password: ""
    },
    resolver: yupResolver(LoginSchema)
  });

  const [showPassword, setPassword] = useState(false)

  return (
    <form
      onSubmit={handleSubmit(props.login)}
      className="auth-form"
    >
        <Controller
            name="email"
            control={control}
            render={({ field }) => 
              <Form.Field className="auth-input">
                  <label>Email</label>
                  <Input 
                    className={`${(touchedFields.email && errors.email)?'error-input-border':''}`}
                    type='email' 
                    placeholder="Example@mail.com" 
                    {...field} 
                  />
              </Form.Field>
            }
        />
        <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) =>  
            <div style={{position: "relative"}}>
                <p
                    className="error-field hook-error"
                >
                {message}
                </p>
            </div>   
            }    
        />
        <Controller
            name="password"
            control={control}
            render={({ field }) => 
              <Form.Field className="auth-input">
                  <label>Password</label>
                  <Input 
                    className={`${(touchedFields.password && errors.password)?'error-input-border':''}`}
                    type={showPassword ? 'text' : 'password'} 
                    placeholder="Enter password"
                    icon={
                      <Icon
                          name={showPassword ? "eye" : "eye slash outline"}
                          size="large"
                          onClick={() => setPassword(!showPassword)}
                      />
                  }
                    {...field} 
                  />
                  <div className="forgot-password-container">
                    <p onClick={()=>props.toggleForgotModal(true)}>Forgot password?</p>
                  </div>
              </Form.Field>
            }
        />
        <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) =>  
            <div style={{position: "relative"}}>
                <p
                    className="error-field hook-error"
                >
                {message}
                </p>
            </div>   
            }    
        />
        <div className="d-flex align-items-center auth-button-wrapp sign-in-btn">
            <Button 
              type="submit" 
              className="auth-button w-100 mx-0"
              style={{
                background: props.mainTheme?.theme?.color ?? '#5CA76A',
              }}
              disabled={props.loading}
              >
                {props.loading 
                  ? <Loader active size='tiny'/> 
                  : 'Sign In'
                }
              </Button>
        </div>
        <p style={{ position: "absolute"}} className="error-field">{props.errorMessage}</p>
    </form>
  )
}