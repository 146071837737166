import React from "react";
import Auth from "../AuthSystem";

const VerificationWrapper = () => {
    return (
        <Auth 
            signUp={false}
            signIn={false}
            confirmEmail={true}
        />
    )
}

export default VerificationWrapper;