import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Checkbox, Input, Form, Icon } from "semantic-ui-react";
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { Loader } from 'semantic-ui-react';

import { SignupSchema } from "./validation";
import { signUpProps } from "./types";

import "./styles.scss";

export const SignUpForm = (props: signUpProps) => {
  const { handleSubmit, setValue, control, getValues, formState: { errors, touchedFields } } = useForm({
    defaultValues: {
        email: "",
        phone: '',
        password1: "",
        password2: "",
        company: '',
        accept: false,
        manager: false
    },
    resolver: yupResolver(SignupSchema)
  });

  const [showFirstPassword, setFirstPassword] = useState(false)
  const [showSecondPassword, setSecondPassword] = useState(false);

  return (
    <form
      onSubmit={handleSubmit(props.register)}
      className="auth-form"
    >
        <Controller
            name="email"
            control={control}
            render={({ field }) => 
                <Form.Field className="auth-input">
                    <label>Email</label>
                    <Input 
                        className={`${(touchedFields.email && errors.email)?'error-input-border':''}`}
                        placeholder="Example@mail.com" 
                        type='email' 
                        {...field} 
                    />
                </Form.Field>
            }
        />
        <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) =>  
            <div style={{position: "relative"}}>
                <p
                    className="error-field hook-error"
                >
                {message}
                </p>
            </div>   
            }    
        />
        <Controller
            name="phone"
            control={control}
            render={({ field }) => 
            <Form.Field className="auth-input">
                <label>Phone number</label>
                <Input 
                    placeholder="Enter phone number" 
                    {...field} 
                />
            </Form.Field>
            }
        />
        <Controller
            name="password1"
            control={control}
            render={({ field }) => 
            <Form.Field className="auth-input">
                <label>Password</label>
                <Input 
                    className={`${(touchedFields.password1 && errors.password1)?'error-input-border':''}`}
                    type={showFirstPassword ? 'text' : 'password'}
                    placeholder="Enter password"
                    icon={
                        <Icon
                            name={showFirstPassword ? "eye" : "eye slash outline"}
                            size="large"
                            onClick={() => setFirstPassword(!showFirstPassword)}
                        />
                    }
                    {...field} 
                />
            </Form.Field>
            }
        />
        <ErrorMessage
            errors={errors}
            name="password1"
            render={({ message }) =>  
            <div style={{position: "relative"}}>
                <p
                    className="error-field hook-error"
                >
                {message}
                </p>
            </div>   
            }    
        />
        <Controller
            name="password2"
            control={control}
            render={({ field }) => 
            <Form.Field className="auth-input">
                <label>Confirm password</label>
                <Input 
                    className={`${(touchedFields.password2 && errors.password2)?'error-input-border':''}`}
                    type={showSecondPassword ? 'text' : 'password'}
                    placeholder="Enter password" 
                    icon={
                        <Icon
                            name={showSecondPassword ? "eye" : "eye slash outline"}
                            size="large"
                            onClick={() => setSecondPassword(!showSecondPassword)}
                        />
                    }
                    {...field} 
                />
            </Form.Field>
            }
        />
        <ErrorMessage
            errors={errors}
            name="password2"
            render={({ message }) =>  
            <div style={{position: "relative"}}>
                <p
                    className="error-field hook-error"
                >
                {message}
                </p>
            </div>   
            }    
        />
        <Controller
            name="company"
            control={control}
            render={({ field }) => 
            <Form.Field className="auth-input">
                <label>Company name</label>
                <Input 
                    placeholder="Enter your company" 
                    {...field} 
                />
            </Form.Field>
            }
        />
        <div className="auth-agreement">
            <div className="d-flex justify-content-between">
                <Checkbox
                    onChange={(e) => setValue("accept", !getValues("accept"))}
                />{" "}
                <div className="ml-2">
                <p>
                    I agree with processing of my personal data in conformity with{' '}
                    <a href='/'>the Privacy Policy.</a> When clicking on “Get Started”, you also agree with{' '}
                    <a href='/'>the End User License Agreement.</a>
                </p>
                </div>
            </div>
            <ErrorMessage
                errors={errors}
                name="accept"
                render={({ message }) =>  
                <div style={{position: "relative"}}>
                    <p
                        className="error-field hook-error"
                    >
                    {message}
                    </p>
                </div>   
                }    
            />
            <div className="d-flex mt-2">
                <Checkbox
                onChange={(e) => setValue("manager", !getValues("manager"))}
                />{" "}
                <div className="ml-2">
                <p>
                    Register as a manager
                </p>
                </div>
            </div>
        </div>
        <div className="d-flex align-items-center auth-button-wrapp">
            <Button 
                type="submit" 
                className="auth-button w-100 mx-0"
                style={{background: props.mainTheme?.theme?.color ?? '#5CA76A' }}
                disabled={props.loading}
            >
                {props.loading 
                  ? <Loader active size='tiny'/> 
                  : 'Get Started'
                }
            </Button>
        </div>
        <p style={{position: "absolute"}} className="error-field">{props.errorMessage}</p>
    </form>
  )
}
