import React from "react";

import "./style.scss";

const PageNotFound = () => (
  <div className="wrapp">
    <h1>404</h1>
    <h2>Page not found</h2>
  </div>
);

export default PageNotFound;
