import React from "react";
import { Link } from "react-router-dom";

import { HOME } from "../../constants/routes";

const AuthFooter = () => {

    const authLinks = [
        {pathname: HOME, title: "© 2023, Ayishi", trademark: true },
        {pathname: HOME, title: "User Agreement" },
        {pathname: HOME, title: "Privacy policy" }
    ]

  return (
    <div className="auth-footer-wrapp">
        {authLinks.map((link, i) => (
            <div 
                key={i}
                className="footer-item-wrapp"
            >
                {link.trademark ?
                    <p>{link.title}</p>
                :
                <Link 
                    to={link.pathname} 
                >
                    <p>{link.title}</p>
                </Link>
                }
            </div>
        ))

        }
    </div>
  )
};

export default AuthFooter;