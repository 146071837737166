import gql from "graphql-tag";

// farmer-focus requests

export const FIELD_MODAL_IMAGE = gql`
  query fieldImageGraph($fieldPk: Int!){
    fieldImageGraph(fieldPk: $fieldPk)
  }
`;

export const DIRECT_MODAL_IMAGE = gql`
  query directSeedingFieldGraphImage($fieldId: Int!){
    directSeedingFieldGraphImage(fieldId: $fieldId)
  }
`;

export const SERIALIZE_SEGMENTATION_FIELD = gql`
query	serializeSegmentationField($communePk: Int!){
  serializeSegmentationField(communePk: $communePk){
    ... on ErrorType {
      __typename
      message
    }
    ... on JSONValue {
      __typename
      value
    }
  }
}
`;

export const SERIALIZE_FARMER_COMMUNES = gql`
query	serializeFarmerCommunes($year: String!, $month: Int!, $crop: String!, $communePk: Int, $week:Int!, $farm: Int, $farmGroup: Int){
  serializeFarmerCommunes(year:$year,month: $month,crop:$crop, communePk: $communePk, week: $week, farm: $farm, farmGroup: $farmGroup){
    ... on JSONValue{
      value
    }
  }
}
`;

export const RANKING_FARMER_REGIONS = gql`
query rankingFarmerRegions($year: String!, $month: Int!, $crop: String!, $week:Int!, $farmGroup: Int){
  rankingFarmerRegions(year:$year,month: $month,crop:$crop, week:$week, farmGroup: $farmGroup){
    ... on RegionsFarmerType{
      regions{
        id
        plantedArea
        harvestForecast
        averageYield
        name
      }
    }
  }
}
`;

export const RANKING_FARMER_COMMUNES = gql`
query rankingFarmerCommunes($year: String!, $month: Int!, $crop: String!, $load: Boolean, $pk: Int, $cp: Boolean!, $week:Int!, $farmGroup: Int){
  rankingFarmerCommunes(year:$year,month: $month,crop:$crop, load:$load, pk: $pk, cp:$cp, week:$week, farmGroup: $farmGroup){
    ...on CommunesFarmerType{
      communes{
        id
        code
        name
        region {
          id
          name
        }
        plantedArea
        harvestForecast
        averageYield
        cp
        subRegion {
          id
        }
      }
    }
  }
}
`;

export const RANKING_FARMER_FARMS = gql`
query rankingFarmerFarmRanking(
  $year: String!,
  $month: Int!,
  $crop: String!,
  $week: Int!,
  $communePk: Int!,
  $cp: Boolean,
  $farm: Int,
  $farmGroup: Int,
  $load: Boolean
  $farmLeader: Int,
) {
  rankingFarmerFarmRanking(
    year: $year,
    month: $month,
    crop: $crop,
    week: $week,
    communePk: $communePk,
    cp: $cp,
    farm: $farm,
    farmGroup: $farmGroup,
    load: $load,
    farmLeader: $farmLeader
  ) {
    ... on ErrorType {
      __typename
      message
    }
    ... on FarmsType {
      __typename
      farms {
        id
        name
        plantedArea
        harvestForecast
        plantedTotal
        availableAgricultureLand
        cropRisk
        cp
        averageNdvi
        averageNdmi
        ndviColor
        ndmiColor
        averageYield
        size
        gdd
      }
    }
    ... on FieldsType {
      __typename
      fields {
        id
        name
        size
        plantedArea
        harvestForecast
        plantedTotal
        availableAgricultureLand
        cropRisk
        crop
        cp
        averageNdvi
        averageNdmi
        ndviColor
        ndmiColor
        averageYield
        communeId
        latitude
        longitude
      }
    }
  }
}
`;

export const RANKING_FARMER_FARMLEADERS = gql`
query rankingFarmerFarmLeadersRanking(
  $year: String!,
  $month: Int!,
  $crop: String!,
  $week: Int!,
  $communePk: Int!,
  $cp: Boolean,
  $farmLeader: Int,
  $farmGroup: Int,
  $load: Boolean
) {
  rankingFarmerFarmLeadersRanking(
    year: $year,
    month: $month,
    crop: $crop,
    week: $week,
    communePk: $communePk,
    cp: $cp,
    farmLeader: $farmLeader,
    farmGroup: $farmGroup,
    load: $load
  ) {
    ... on ErrorType {
      __typename
      message
    }
    ... on FarmersType {
      __typename
      farmers {
        id
        name
        mobile
        plantedArea
        harvestForecast
        plantedTotal
        availableAgricultureLand
        cropRisk
        cp
        averageNdvi
        averageNdmi
        ndviColor
        ndmiColor
        averageYield
        size
        gdd
        fieldGrownStage
      }
    }
  }
}
`;

export const TOTAL_FARMER_VALUES = gql`
query totalFarmerValues(
  $year: String!, 
  $month: Int!, 
  $crop: String!, 
  $pk: Int, 
  $communePk: Int, 
  $week:Int!, 
  $farm: Int, 
  $farmLeader: Int, 
  $farmGroup: Int
  ){
  totalFarmerValues(
    year:$year, 
    month: $month, 
    crop: $crop, 
    pk: $pk, 
    communePk: $communePk, 
    week: $week, 
    farm: $farm, 
    farmLeader: $farmLeader, 
    farmGroup: $farmGroup
    )
}
`;


export const COMMUNE_IMAGE = gql`
  query communeImage($year: String!, $filters: String!, $month: Int!, $communePk: Int!, $week:String){
    communeImage(year:$year,month: $month, filters:$filters, communePk: $communePk, week: $week)
  }
`;

export const FIELD_IMAGE = gql`
  query fieldImage($year: String!, $filters: String!, $month: Int!, $fieldPk: Int!, $week:String){
    fieldImage(year:$year,month: $month, filters:$filters, fieldPk: $fieldPk, week: $week)
  }
`;

export const DATA_COUNT = gql`
query dataCount($year: String!) {
  dataCount(year: $year)
}
`;

export const FARM_LEADER_RANKING = gql`
  query farmLeaderRanking($year: String!, $month: Int!, $communePk: Int!, $week:Int!, $crop: String!, $farmGroup: Int){
    farmLeaderRanking(year:$year,month: $month, communePk: $communePk, week: $week, crop: $crop, farmGroup: $farmGroup)
  }
`;